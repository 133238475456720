import {computed, ref, onMounted} from 'vue'
import {useRouter} from 'vue-router'
import XEUtils from "xe-utils";
import {getUserInof, removeUserInfo} from "../../../api/cookie";
import {cutString} from "../../../api/GongJu";
import {getDaiPingJiaLength, setFws, setKeHu} from "../../../api/dataList/userApi";
import http from "@/api/http";
import _ from 'lodash'

export function userBasis() {
    const userInfo = getUserInof()
    const router = useRouter()
    /**
     * true = 是负责人
     * @type {ComputedRef<boolean>}
     */
    let isFzr = computed(() => {
        return userInfo?.isFzr === true
    })

    /**
     * 职位
     *  '' = 没有公司
     *  负责人
     *  员工
     * @type {ComputedRef<string>}
     */
    let zhiWei = computed(() => {
        if (XEUtils.toInteger(userInfo?.dang_qian_g_id) === 0) {
            return ''
        }
        if (isFzr.value) return '负责人'
        return '员工'
    })

    /**
     * 获取角色
     *   客户、企业服务商、个人服务商
     * @type {ComputedRef<unknown>}
     */
    let jiaoSe = computed(() => {
        if (userInfo?.dang_qian_jiao_se === '服务商') {
            if (userInfo?.fws_type === '企业') {
                return '企业服务商'
            }
            return '个人服务商'
        }
        return '客户'
    })


    /**
     * 退出
     */
    function tuiChu() {
        removeUserInfo().then(da => {
            router.push({
                name: 'UserLogin'
            })
        })
    }

    //true = 有公司
    let notGongSi = computed(() => {
        let g_name = userInfo?.g_name
        return XEUtils.isString(g_name) && !XEUtils.isEmpty(g_name)
    })

    /**
     * 公司名称
     * @type {ComputedRef<string|*|string>}
     */
    let gName = computed(() => {

        let g_name = userInfo?.g_name
        if (!notGongSi.value) {
            return '没有关联公司'
        }
        return cutString(g_name, 10)
    })


    //切换到客户
    function userSetkeHu() {
        setKeHu().then(da => {
            if (da.code === 1) {
                location.reload()
            }
        })
    }

    //切换到服务商
    function userSetFws() {
        setFws().then(da => {
            if (da.code === 1) {
                location.reload()
            }
        })
    }

    return {
        userSetkeHu,
        userSetFws,
        gName,
        tuiChu,
        isFzr,
        zhiWei,
        jiaoSe,
        userInfo,
        notGongSi
    }
}

/**
 * 消息
 */
export function userMessage() {
    let weiDu = ref(0); //未读消息数量
    onMounted(() => {
        getLength()
    })

    function getLength() {
        http.get('/gzhphp/user/getMessageUnread').then(da => {
            if (_.get(da, 'code') === 1) weiDu.value = da.data.number
        })
    }

    return {
        weiDu,
    }

}

/**
 * 获取侍评论数量
 */
export function getDaiPingLun() {
    let daiPingJiaLength = ref(0) //待评价数量
    onMounted(() => {
        daiPingJia()
    })


    async function daiPingJia() {
        daiPingJiaLength.value  =await getDaiPingJiaLength()
    }


    return {
        daiPingJiaLength
    }

}
