<template>
  <nav-header fixed title="个人信息" />
  <van-form  v-if="JSON.stringify(userInfo) != '{}'">
    <div class="tou-xiang">
      <van-image width="90" height="90" round :src="userInfo.avatar"/>
    </div>
    <div class="padding-top-15">
      <van-cell-group inset>
        <van-field
            readonly
            v-model="userInfo.user_nickname"
            label="用户名"
        />
        <van-field
            readonly
            v-model="userInfo.sex.name"
            label="性别"
        />
        <van-field
            readonly
            v-model="userInfo.address"
            label="常驻地址"
        />
      </van-cell-group>
    </div>
    <div class="padding-top-15">
      <van-cell-group inset>
        <van-field
            readonly
            v-model="userInfo.position"
            label="职位"
        />
        <van-field
            readonly
            v-model="userInfo.wx_number"
            label="微信号码"
        />
        <van-field
            readonly
            v-model="userInfo.qq_number"
            label="QQ号码"
        />
        <van-field
            readonly
            v-model="userInfo.birthday"
            label="出生日期"
        />
      </van-cell-group>
    </div>

    <div style="margin: 16px;">
      <van-button round block :to="{name:'UserEditInfo'}"  >
        编辑
      </van-button>
    </div>
   <div class="edit-btn" v-if="isFzr">
     <router-link :to="{name:'EditCompanyInfo'}">{{editName}}</router-link>
   </div>
    <div class="edit-btn" v-else-if="!notGongSi">
      <template v-if="jiaoSe ==='客户'">
        <router-link :to="{name:'CompanyInfoEdit'}" >完善公司信息</router-link><br/>
      </template>
      <template v-else>
        <router-link :to="{name:'fuWuShangRuZhu'}" >申请服务商</router-link><br/>
        <router-link :to="{name:'gcs_index'}">申请个人工程师</router-link>
      </template>
    </div>
  </van-form>
</template>

<script>
import NavHeader from "../../components/top/NavHeader";
import http from "../../api/http";
import moment from "moment";
import basis from "../../components/class/basis";

export default {
  name: 'UserInfo',
  components: {NavHeader},
  mixins: [basis],
  data() {
    return {
      userInfo: {}
    }
  },
  mounted() {
    this.getInfo()
  },
  methods: {
    getInfo() {
      http.get('/gzhphp/user/getUserInfo').then(da => {
        if (da.code === 1) {
          da.data.birthday = this.time(da.data.birthday)
          this.userInfo = da.data
        }
      })
    }
  },
  watch: {},
  computed: {
    editName(){
      if(this.userInfo.dang_qian_jiao_se === '客户'){
        return '客户信息'
      }
      return '服务商信息'
    }
  }
}
</script>
<script setup>
import {userBasis} from "./public/Basis";

let {isFzr,notGongSi,jiaoSe} = userBasis()
</script>

<style scoped>
.tou-xiang {
  text-align: center;
  margin-top: 15px;
}
.edit-btn{
  margin: 10px;
  line-height: 30px;
}
</style>
