import http from "@/api/http";
import {Toast, Dialog} from "vant";
import {setUserInfo, getUserInof as sessionGetUserInof, updateUserInfo} from "../cookie";
import XEUtils from "xe-utils";

export const getUserInfo = () => {
    return http.get('/gzhphp/user/getUserInfo?ran_id')
}
//切换到服务商
export const setFws = async () => {
    await http.get('/gzhphp/user/setFws')
    return new Promise((s) => {
        updateUserInfo().then(da => {
            s(da)
        })
    })
}
//切换客户
export const setKeHu = () => {
    return http.get('/gzhphp/user/setKehu').then(da => {
        return new Promise((s) => {
            updateUserInfo().then(da => {
                s(da)
            })
        })
    })
}
export const getDaiPingJiaLength = () => {
    return http.get(
        '/gzhphp/user_xiang_mu/getXiangMuList',
        {
            params: {
                isDaiPingJia: true,
                data_length: 1,
            }
        }
    ).then(da => {
        return new Promise((s) => {
            if (da.code === 1) {
                s(da.data.total)
            } else {
                s(0)
            }
        })
    })
}


let userApi = {
    /**
     * 获取进度用户信息
     * @returns {Promise<unknown>}
     */
    jinDuUser() {
        return new Promise((s, e) => {
            http.get("/user/jinduindex/get_info").then(da => {
                if (da.code == 1) {
                    s(da.data)
                } else {
                    e(da.msg)
                }

            }).catch(e => {
                e(e)
            })
        })
    }
}
export default userApi
